import React, { useEffect, useRef, useState } from 'react';
import map from "./assets/map3.png";
import Globe from "react-globe.gl";
import { useInterval } from './util';

const SampleWorld = () => {
    const globeEl = useRef();
    const containerRef = useRef();
    const [dimensions, setDimensions] = useState({ width: 400, height: 400 });

    useEffect(() => {
        globeEl.current.controls().autoRotate = true;
        globeEl.current.controls().autoRotateSpeed = 1;
        globeEl.current.controls().enableZoom = false;
        globeEl.current.controls().maxDistance = 2000;
        globeEl.current.controls().minDistance = 50;
        

        const updateDimensions = () => {
            const width = containerRef.current.offsetWidth;
            const height = width; // Adjust the aspect ratio as needed
            setDimensions({ width, height });
        };

        window.addEventListener('resize', updateDimensions);
        updateDimensions();

        return () => window.removeEventListener('resize', updateDimensions);
    }, []);

    const lockRef = useRef(false);

    const [arcsData, setArcsData] = useState([]);
    const [ringsData, setRingsData] = useState([]);
    const ARC_REL_LEN = 0.7; // relative to whole arc
    const FLIGHT_TIME = 2500; // 2 saniye sonra okları kaldır
    const NUM_RINGS = 2;
    const RINGS_MAX_R = 3; // deg
    const RING_PROPAGATION_SPEED = 5; // deg/sec
    const MAP_CENTER = { lat: 39.0, lng: 35.0, altitude: 2.0 };
    const arcColors = {
        "tcp-portscan": "lightblue",
        "ssh-login": "orange",
        "ssh-command": "yellow",
        "ftp-command": "yellow",
        "fortinet-login": "pink",
        "vsphere-login": "pink",
        "dns-query": "#00b8ff",
        "postgresql-login": "purple",
        "ldap-login": "purple",
        "mssql-login": "purple",
        "mysql-login": "purple",
        "filemanager-login": "pink",
        "adminer-login": "pink",
        "phpmyadmin-login": "pink",
        "nextcloud-login": "pink",
    };

    const acquireLock = () => {
        if (lockRef.current) {
            return false; // Lock zaten alınmış
        }
        lockRef.current = true;
        return true;
    };

    const releaseLock = () => {
        lockRef.current = false;
    };

    useEffect(() => {
        const currentUrl = new URL(window.location.href);
        const ws = new WebSocket(`wss://${currentUrl.hostname}/connect/ws`);

        if (ws.CLOSED) {
            ws.onopen = () => {
                ws.send("livelog");
                ws.send("liveness");
                setInterval(() => {
                    if (ws.OPEN) {
                        ws.send("liveness");
                    } else {
                        const ws = new WebSocket(`wss://${currentUrl.hostname}/connect/ws`);
                    }
                }, 10000);
            };
        }

        ws.onmessage = (event) => {
            let data;
            try {
                data = JSON.parse(event.data);
            } catch (error) {
                data = "live";
            }
            if (data?.data?.logs) {
                if (acquireLock()) {
                    data?.data?.logs?.map(item => JSON.parse(item)).forEach((log) => {
                        const from = log?.data?.attackerLocation;
                        const to = log?.data?.agentLocation;
                        const startLat = parseFloat(from.long);
                        const startLng = parseFloat(from.lat);
                        const endLat = parseFloat(to.lat);
                        const endLng = parseFloat(to.long);
                        const arc = {
                            startLat,
                            startLng,
                            endLat,
                            endLng,
                            type: log.data.record.type,
                        };
                        setArcsData(prevArcs => {
                            const newArcs = [...prevArcs, arc];

                            // Timeout ile oku belirli bir süre sonra kaldırma
                            setTimeout(() => {
                                setArcsData(arcs => arcs.filter(a => a !== arc));
                            }, FLIGHT_TIME); // FLIGHT_TIME süresi sonunda oku kaldır

                            return newArcs;
                        });

                        const srcRing = {
                            lat: startLat,
                            lng: startLng,
                            type: log.data.record.type,
                        };
                        setRingsData(prevRings => {
                            const newRings = [...prevRings, srcRing];

                            // Timeout ile ringi belirli bir süre sonra kaldırma
                            setTimeout(() => {
                                setRingsData(rings => rings.filter(r => r !== srcRing));
                            }, FLIGHT_TIME); // FLIGHT_TIME süresi sonunda ringi kaldır

                            return newRings;
                        });
                    });
                    releaseLock();
                }
            }
        };
    }, []);

    return (
        <>
            <div ref={containerRef} style={{ width: '100%', height: '100%' }}>
                <Globe
                    ref={globeEl}
                    globeImageUrl={map}
                    bumpImageUrl="//unpkg.com/three-globe/example/img/earth-topology.png"
                    width={dimensions.width}
                    height={dimensions.height}
                    backgroundColor="rgba(255, 255, 255, 0.0)"
                    arcsData={arcsData}
                    ringsData={ringsData}
                    showAtmosphere={false}
                    arcColor={(d) => arcColors[d.type] || "darkOrange"}
                    arcStroke={0.7} // Okların kalınlığını arttırmak için stroke değeri eklendi
                    arcDashLength={0.5}
                    arcDashGap={0.3}
                    arcDashAnimateTime={2500}
                    atmosphereAltitude={0.1}
                    ringColor={(d) => arcColors[d.type] || "darkOrange"}
                    ringMaxRadius={RINGS_MAX_R}
                    ringPropagationSpeed={RING_PROPAGATION_SPEED}
                    ringRepeatPeriod={(FLIGHT_TIME * ARC_REL_LEN) / NUM_RINGS}
                />
            </div>
        </>
    );
}

export default SampleWorld;
