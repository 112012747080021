import React, { useEffect } from 'react';
import { LazyLog, ScrollFollow, onScroll, follow} from 'react-lazylog';

const Footer = ({ currentLog, agentState }) => {
   
  return (
    <div className="footer-container">
      <div className="box left-box">
        <div className="tab">Top Attackers Country</div>
        <div className="text">Soon</div>
      </div>
      <div className="mid-box center-box">
        <div className="tab">Live Logs</div>
        <div style={{ minHeight: 300, minWidth: 500, alignContent: 'left', textAlign: 'left' }}>
  {currentLog && (
    <ScrollFollow
      startFollowing={false} // Başlangıçta otomatik olarak takip etmeyi devre dışı bırak
      render={({ onScroll, follow, startFollowing, stopFollowing }) => (
        <LazyLog
          extraLines={0}
          disableSearch
          showLineNumber={false}
          text={currentLog?.data?.logs.map(log => {
            const parsedLog = JSON.parse(log); // log değişkenini parse ediyoruz
            const attackerLocation = parsedLog?.data?.attackerLocation?.country || ''; // attackerLocation'ı alıyoruz
            //const ipAddress = parsedLog?.data?.record?.ip_address || ''; // ipAddress'ı alıyoruz
            const attackType = parsedLog?.data?.record?.type
            const attackTime = parsedLog?.data?.record?.time
            return `${attackType} from ${attackerLocation} at ${attackTime}` ;
          }).join('\n')}
          stream
          onScroll={onScroll}
          follow={follow}
          style={{ paddingBottom: '10px' }} // Logların altında bir boşluk bırakarak ekranda tam ortasına gelmesini engelle
        />
      )}
    />
  )}
</div>
      </div>
      <div className="box right-box">
        <div className="tab">Statistics</div>
        <div className='text'>


        Live Guardpot:  {agentState?.data?.response?.stat?.live}
   <br></br>   <br></br>
        Dead Guardpot:  {agentState?.data?.response?.stat?.dead}
        </div>
      
      </div>
    </div>
  );
};

export default Footer;