import React, { useEffect, useRef, useState } from "react";
import Globe from "react-globe.gl";
import Head from "./Head";
import Footer from "./Footer";
import { isMobile } from "react-device-detect";
const HeatMap = () => {
  const globeEl = useRef();
  //const gData = [];
  const [gData, setGData] = useState([]);
  const [currentRecord, setCurrentRecord] = useState(null);
  const [currentLog, setCurrentLog] = useState(null);
  const [agentState, setAgentState] = useState(null);
  useEffect(() => {
    const currentUrl = new URL(window.location.href);
    const ws = new WebSocket(`wss://${currentUrl.hostname}/connect/ws`);
   // const ws = new WebSocket("wss://machine.prientecloud.com/connect/ws");

    if (ws.CLOSED) {
      ws.onopen = () => {
        console.log("WebSocket Connected");
        ws.send("heatmap");
        ws.send("livelog");
        setInterval(() => {
          ws.send("liveloghb");
          ws.send("liveness");
        }, 10000);
      };
    }
    ws.onmessage = (event) => {
      let data;
      try {
        data = JSON.parse(event.data);
      } catch (error) {
        data = "live";
      }
      if (data?.data?.heatmap) {
        const data = JSON.parse(event.data);
        setGData(
          data?.data?.heatmap.map((item) => ({
            lat: parseFloat(item.lon), // item.lat doğru değere erişim sağlar
            lng: parseFloat(item.lat), // item.lon doğru değere erişim sağlar, ve burada 'lng' olarak adlandırıldı
            weight: item.elevation, // Ağırlık değeri her bir eleman için rastgele atanır
          }))
        );
      } else if (data?.data?.response) {
        const data = JSON.parse(event.data);
        setAgentState(data);
      } else if (data?.data?.logs) {
        data?.data?.logs?.map((log, index) => {
          const logInner = JSON.parse(log);
          const from = logInner?.data?.attackerLocation;
          const to = logInner?.data?.agentLocation;
          const record = logInner?.data?.stats;
          const startLat = parseFloat(from.long);
          const startLng = parseFloat(from.lat);
          const endLat = parseFloat(to.lat);
          const endLng = parseFloat(to.long);
          setCurrentRecord(record); // record verisini set ediyoruz
          setCurrentLog(data);
        });
      } else {
        //console.log('live')
      }
    };
    return () => {
      if (ws) {
        ws.close();
      }
    };
  }, []);

  return (
    <>
      <Head isMobil={isMobile} record={currentRecord} />
      <Globe
        ref={globeEl}
        globeImageUrl="//unpkg.com/three-globe/example/img/earth-night.jpg"
        backgroundColor="rgba(0, 0, 0, 0.5)"
        heatmapsData={[gData]}
        heatmapPointLat={"lat"}
        heatmapPointLng={"lng"}
        heatmapPointWeight={"weight"}
        heatmapTopAltitude={0}
        heatmapsTransitionDuration={3000}
        enablePointerInteraction={false}
      />
      <Footer currentLog={currentLog} agentState={agentState} />
    </>
  );
};

export default HeatMap;
