import React from "react";
import {BrowserRouter, Navigate, Route, Routes} from "react-router-dom";
import Landing from "./Landing";
import GlobeWithWebSocketData from "./MapChart";
import HeatMap from "./heatmap";
import { Helmet } from "react-helmet";

const AppRouter = () => {
  const schemaMarkup = {
    "@context": "http://schema.org",
    "@type": "SoftwareApplication",
    "name": "Guardpot",
    "description": "Guardpot offers advanced honeypot software to attract cyber attackers and provide insights into their methods. Discover our security solutions.",
    "applicationCategory": "SecurityApplication",
    "operatingSystem": "Cross-Platform",
    "url": "https://guardpot.com",
    "image": "https://guardpot.com/assets/img/logo512.png",
    "author": {
      "@type": "Organization",
      "name": "Guardpot Inc."
    }
  };

  return (
    <>

      <Helmet>
        <meta charSet="utf-8"/>
        <meta
            name="viewport"
            content="width=device-width, initial-scale=1.0, shrink-to-fit=no"
        />
        <title>Guardpot | Honeypot Cybersecurity Software</title>
        <meta name="description"
              content="Guardpot offers advanced honeypot software to attract cyber attackers and provide insights into their methods. Discover our security solutions."/>
        <meta name="keywords"
              content="cyber decoy, honeypot cybersecurity, honeypot software, cyber threat detection, cybersecurity solutions, Guardpot, advanced honeypot, cyber attack analysis, threat intelligence, network security, cybersecurity tools"/>
        <link rel="robots" href="https://guardpot.com/robots.txt"/>
        <link rel="canonical" href="https://guardpot.com"/>
        <meta name="copyright" content="Priente Software"/>
        <meta name="author" content="Priente Software Team"/>
        <meta property="og:title" content="Guardpot: Advanced Honeypot Cybersecurity Solutions"/>
        <meta property="og:description"
              content="Guardpot offers advanced honeypot software to attract cyber attackers and provide insights into their methods. Discover our security solutions."/>
        <meta property="og:url" content="https://guardpot.com"/>
        <meta property="og:site_name" content="Guardpot"/>
        <meta property="og:type" content="website"/>
        <meta property="og:image" content="https://guardpot.com/assets/img/logo512.png"/>
        <meta name="twitter:card" content="summary"/>
        <meta name="twitter:site" content="@guardpot"/>
        <meta name="twitter:title" content="Guardpot: Advanced Honeypot Cybersecurity Solutions"/>
        <meta name="twitter:description"
              content="Guardpot offers advanced honeypot software to attract cyber attackers and provide insights into their methods. Discover our security solutions."/>
        <meta name="twitter:image" content="https://guardpot.com/assets/img/logo512.png"/>

        <script type="application/ld+json">{JSON.stringify(schemaMarkup)}</script>

        <link
            rel="icon"
            type="image/png"
            sizes="32x32"
            href="assets/img/favicon-g.png"
        />
        <link rel="stylesheet" href="assets/bootstrap/css/bootstrap.min.css"/>
        <link
            rel="stylesheet"
            href="https://fonts.googleapis.com/css?family=Exo+2&subset=cyrillic,cyrillic-ext&display=swap"
        />
        <link
            rel="stylesheet"
            href="assets/css/Font%20Awesome%205%20Brands.css"
        />
        <link rel="stylesheet" href="assets/css/Font%20Awesome%205%20Free.css"/>
        <link
            rel="stylesheet"
            href="https://fonts.googleapis.com/css?family=Open+Sans&display=swap"
        />
        <link
            rel="stylesheet"
            href="https://fonts.googleapis.com/css?family=Varela+Round&display=swap"
        />
        {/* <link rel="stylesheet" href="assets/fonts/fontawesome-all.min.css"/>
        <link rel="stylesheet" href="assets/css/animate.min.css"/>
        <link rel="stylesheet" href="assets/css/bootstrap.min.css"/>
        <link rel="stylesheet" href="assets/css/fontawesome-all.min.css"/>
        <link rel="stylesheet" href="assets/css/swiper.css"/>
        <link rel="stylesheet" href="assets/css/styles-1.css"/> */}
      </Helmet>
      <div>
        <BrowserRouter basename="/">
          <Routes>
            <Route>
              <Route path="/" element={<Landing/>}/>
              <Route path="/live" element={<GlobeWithWebSocketData/>}/>
              <Route path="/heatmap" element={<HeatMap/>}/>
              <Route path="*" element={<Navigate to="/" />} />
            </Route>
          </Routes>
        </BrowserRouter>
      </div>
    </>

  );
};

export default AppRouter;
