import React from "react";
import image1 from "./assets/image-logo.png"
import image2 from "./assets/image-text.png"
const Head = ({ record, isMobil }) => {

  const settings = {
    dots: false,
    arrows: false,
    centerMode: true,
    infinite: true,
    centerPadding: "60px",
    slidesToShow: 3,
    speed: 500
  };

  return (
    <>

      <header>
        <div class="header" style={{ background: '#131521', color: 'white' }}>
          <div class="logo">
            <a href="/" className={`px-3 d-flex align-items-center justify-content-center ${isMobil ? 'flex-column py-2' : ''} `} >
              <img src={image1} alt="Logo"></img>
              <span className="reversecolor" >
                <img src={image2} style={{ width: '120px' }} className="m-3" alt="Logo2"></img>
              </span>
            </a>
          </div>
          {isMobil ?
            <>
              <div className="w-100 d-flex ">

                <div className="w-65" >

                  <div className="headerItem" style={{ textAlign: 'end' }}>
                    <span className="headerLabel">TOTAL UNIQUE IP </span>
                  </div>

                  <div className="headerItem" style={{ textAlign: 'end' }}>
                    <span className="headerLabel">TOTAL UNIQUE CREDENTIAL </span>
                  </div>
                  <div className="headerItem" style={{ textAlign: 'end' }}>
                    <span className="headerLabel"> TOTAL CREDENTIAL ATTEMPTS </span>
                  </div>

                  <div className="headerItem" style={{ textAlign: 'end' }}>
                    <span className="headerLabel"> TOTAL UNIQUE IP-CREDENTIAL </span>
                  </div>

                </div>

                <div className="w-35">

                  <div className="headerItem" style={{ textAlign: 'start' }}>
                    <span className="headerValue">{record?.uniqueipcount}</span>
                  </div>
                  <div className="headerItem" style={{ textAlign: 'start' }}>
                    <span className="headerValue">{record?.uniquecredcount}</span>
                  </div>
                  <div className="headerItem" style={{ textAlign: 'start' }}>
                    <span className="headerValue">{record?.totalcredcount}</span>
                  </div>
                  <div className="headerItem" style={{ textAlign: 'start' }}>
                    <span className="headerValue">{record?.uniqueipcredcount}</span>
                  </div>
                </div>


              </div>

            </>
            :
            <>
              <div className="w-100 mt-1">

                <div className="wrapperCred" style={{ marginTop: '5px' }}>
                  <div className="headerItem">
                    <span className="headerLabel"> <span className="headerSubItem">TOTAL</span> UNIQUE IP  </span> <span className="headerValue">{Number(record?.uniqueipcount) > 0 ? record?.uniqueipcount : 'Loading'}</span>
                  </div>
                  <div className="headerItem">
                    <span className="headerLabel">  <span className="headerSubItem">TOTAL</span> UNIQUE CREDENTIAL </span> <span className="headerValue">{Number(record?.uniquecredcount) > 0 ? record?.uniquecredcount : 'Loading'}</span>
                  </div>
                  <div className="headerItem">
                    <span className="headerLabel"> <span className="headerSubItem">TOTAL</span> CREDENTIAL ATTEMPTS </span> <span className="headerValue">{Number(record?.totalcredcount) > 0 ? record?.totalcredcount : 'Loading'}</span>
                  </div>

                  <div className="headerItem">
                    <span className="headerLabel" > <span className="headerSubItem">TOTAL</span> UNIQUE IP-CREDENTIAL </span> <span className="headerValue">{Number(record?.uniqueipcredcount) > 0 ? record?.uniqueipcredcount : 'Loading'}</span>
                  </div>


                </div>

              </div>

              <div>

              </div>

            </>
          }
        </div>
      </header>
    </>

  );
};
export default Head;
