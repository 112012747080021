import React, { useState } from "react";
import searchImg from "./assets/filter-button.png";
const LiveStats = ({ currentLog, agentState }) => {

  const [tab, setTab] = useState(true);

  const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  function formatDate(date) {
    if (date) {
      const isoString = new Date(date)?.toISOString();
      const dateEdit = new Date(isoString);
      const gmtString = dateEdit
        ?.toUTCString()
        ?.toLocaleString("tr-TR", { timeZone: userTimezone });
      const gmtDate = new Date(gmtString);
      const localDate = gmtDate?.toLocaleString("tr-TR");
      return localDate;
    }
  }
  const [count, setCount] = useState(10);
  // const data = [{}, {}, {}, {}]
  const [search, setSearch] = useState("");

  const keys = [{ inside: false, field: "country"},{ inside: false, field: "username"},{ inside: false, field: "password"}];
  const filter = (values) => {
    return values?.filter((log) =>
      keys.some((key) =>
        key.inside
          ? key.insideArray.some((_, i) => {
            return String(log[key.field][key.insideArray[i]])
              .toLowerCase()
              .includes(search.toLowerCase());
          })
          : String(log[key.field]).toLowerCase().includes(search.toLowerCase())
      )
    );
  };
  /*  console.log(agentState?.data?.response?.heatmap); */
  /*   console.log(Array.isArray(agentState?.data?.response?.heatmap)); */

  console.log(currentLog);

  return (
    <>



      <div className="grap-card-white h-100 p-3 livestats-container">


        <div className="d-flex align-items-center justify-content-between attack-item" style={{ background: '#131521', color: 'white' }}>
          <div
            className="p-3 pb-3 "
            style={{ fontSize: "20px", fontWeight: "600" }}
          >
            <ins style={{ textDecoration: "none" }}>Statistics</ins>
          </div>
          <div className="reversecolor agents-search-bar pb-1">
            <img
              src={searchImg}
              height={24}
              width={24}
              alt="circle"
              className=""
              style={{ color: "white" }}
            />
            <input
              type="text"
              className="none-border w-100"
              step={{ color: 'white' }}
              placeholder=" Filter"
              style={{ color: "white" }}
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>
          <div>
            <div className="d-flex align-items-center">
              <button
                style={{
                  fontSize: "32px",
                  lineHeight: "2px",
                  fontWeight: "bold",
                  color: "gray",
                  background: "none",
                  border: "none",
                }}
                onClick={() => setCount(count - 1 > 0 ? count - 1 : 0)}
              >
                -
              </button>
              <div
                className="py-1 px-2"
                style={{
                  boxShadow: "0px 0px 8px 0px #100B2714",
                  borderRadius: "8px",
                  fontSize: "20px",
                }}
              >
                {count}
              </div>
              <button
                style={{
                  fontSize: "32px",
                  lineHeight: "2px",
                  fontWeight: "bold",
                  color: "gray",
                  background: "none",
                  border: "none",
                }}
                onClick={() => setCount(count + 1)}
              >
                +
              </button>
            </div>
          </div>
        </div>

        <div style={{ overflowY: "auto" }}>
          <div className="d-flex align-items-center attack-item" style={{ background: '#131521', color: 'white' }}>
            <div className="d-flex align-items-center justify-content-between p-3 h-100 w-100">
              <div>
                <div className="h-100">
                  <div style={{ textAlign: "right" }}>
                    <strong> Live Agents</strong>
                  </div>

                  <div style={{ marginTop: "auto" }}>
                    {agentState !== null
                      ? agentState?.data?.response?.stat?.live
                      : "Loading.."}
                  </div>
                </div>
              </div>
              <div className="h-100">
                <div style={{ textAlign: "right" }}>
                  <strong> Dead Agents</strong>
                </div>

                <div style={{ color: "red", marginTop: "auto" }}>
                  {agentState !== null
                    ? agentState?.data?.response?.stat?.dead
                    : "Loading.."}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="d-flex align-items-center attack-item attacker-tab p-0" style={{ background: '#131521', color: 'white', overflow: 'hidden' }}>

          <button className={tab && 'selected'} onClick={() => setTab(true)}>
             Country
          </button>
          <button className={!tab && 'selected'} onClick={() => setTab(false)}>
             Credentials
          </button>

        </div>

        {tab ?
          <>

            <div style={{ overflowY: "auto" }}>
              {agentState !== null
                ? filter(Object.values(agentState?.data?.response?.heatmap))
                  .sort((a, b) => b?.count - a?.count)
                  ?.slice(0, count)
                  .map((item) => {
                    return (
                      <div className="d-flex align-items-center heatmap-item" style={{ background: '#131521', color: 'white' }}>
                        <div className="d-flex align-items-center justify-content-between py-1 px-3 h-50 w-100">
                          <div>
                            <strong style={{ fontSize: "12px" }}>
                              {item.country}
                            </strong>
                            <br />
                          </div>
                          <div className="h-100">
                            <div style={{ marginTop: "auto", }}>
                              <ins style={{ textDecoration: "none" }}>{item.count}</ins>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })
                : null}
            </div>
          </>
          :
          <>

            <div style={{ overflowY: "auto" }}>
              {agentState !== null
                ? filter(Object.values(currentLog)?.filter(item => typeof item == "object"))
                  .sort((a, b) => Number(b?.count) - Number(a?.count))
                  ?.slice(0, count)
                  .map((item) => {
                    return (
                      <div className="d-flex align-items-center heatmap-item" style={{ background: '#131521', color: 'white' }}>
                        <div className="d-flex align-items-center justify-content-between py-1 px-3 h-50 w-100">
                          <div>
                            <strong style={{ fontSize: "12px" }}>
                              {item.username?.length > 0 ? item.username + " - " + item.password : ''}
                            </strong>
                            <br />
                          </div>
                          <div className="h-100">
                            <div style={{ marginTop: "auto", }}>
                              <ins style={{ textDecoration: "none" }}>{item.count}</ins>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })
                : null}
            </div>
          </>}

      </div>
    </>

  );
};

export default LiveStats;
