/* eslint-disable jsx-a11y/alt-text */
import React, { useState } from "react";
import adminer from "./assets/adminer.png";
import dns from "./assets/dns.png";
import filemanager from "./assets/filemanager.png";
import fortinet from "./assets/fortinet.png";
import ftp from "./assets/ftp.png";
import ldap from "./assets/ldap.png";
import mssql from "./assets/mssql.png";
import mysql from "./assets/mysql.png";
import nextcloud from "./assets/nextcloud.png";
import pfsense from "./assets/pfsense.png";
import phpmyadmin from "./assets/phpmyadmin.png";
import postgresql from "./assets/postgre.png";
import request from "./assets/request.png";
import smtp from "./assets/smtp.png";
import ssh from "./assets/ssh.png";
import tcp from "./assets/tcp.png";
import udp from "./assets/udp.png";
import vsphere from "./assets/vsphere.png";

const LastAttacks = ({ data = [], windowSize, totalData = 0, isMobile }) => {
  const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  function formatDate(date) {
    if (date) {
      const isoString = new Date(date)?.toISOString();
      const dateEdit = new Date(isoString);
      const gmtString = dateEdit
        ?.toUTCString()
        ?.toLocaleString("tr-TR", { timeZone: userTimezone });
      const gmtDate = new Date(gmtString);
      const localDate = gmtDate?.toLocaleString("tr-TR");
      return localDate;
    }
  }

  const [search, setSearch] = useState("");
  const keys = [{ inside: true, field: "record", insideArray: ["loglevel"] }];
  const filter = (values) => {
    return values?.filter((log) =>
      keys.some((key) =>
        key.inside
          ? key.insideArray.some((_, i) => {
              return String(log[key.field][key.insideArray[i]])
                .toLowerCase()
                .includes(search.toLowerCase());
            })
          : String(log[key.field]).toLowerCase().includes(search.toLowerCase())
      )
    );
  };

  const ImageComp = ({ attack }) => {
    return (
      <>
        {attack.AttackType === "dns-query" ? (
          <img src={dns} width={40} height={40} />
        ) : attack.AttackType === "imap-request" ? (
          <img src={request} width={40} height={40} />
        ) : attack.AttackType === "mysql-login" ? (
          <img src={mysql} width={40} height={40} />
        ) : attack.AttackType === "smtp-request" ? (
          <img src={smtp} width={40} height={40} />
        ) : attack.AttackType === "tcp-portscan" ? (
          <img src={tcp} width={40} height={40} />
        ) : attack.AttackType === "udp-portscan" ? (
          <img src={udp} width={40} height={40} />
        ) : attack.AttackType === "pfsense-login" ? (
          <img src={pfsense} width={40} height={40} />
        ) : attack.AttackType === "vsphere-login" ? (
          <img src={vsphere} width={40} height={40} />
        ) : attack.AttackType === "fortinet-login" ? (
          <img src={fortinet} width={40} height={40} />
        ) : attack.AttackType === "ssh-login" ? (
          <img src={ssh} width={40} height={40} />
        ) : attack.AttackType === "ssh-command" ? (
          <img src={ssh} width={40} height={40} />
        ) : attack.AttackType === "ftp-login" ? (
          <img src={ftp} width={40} height={40} />
        ) : attack.AttackType === "ftp-command" ? (
          <img src={ftp} width={40} height={40} />
        ) : attack.AttackType === "mssql-login" ? (
          <img src={mssql} width={40} height={40} />
        ) : attack.AttackType === "ldap-login" ? (
          <img src={ldap} width={40} height={40} />
        ) : attack.AttackType === "postgresql-login" ? (
          <img src={postgresql} width={40} height={40} />
        ) : attack.AttackType === "nextcloud-login" ? (
          <img src={nextcloud} width={40} height={40} />
        ) : attack.AttackType === "phpmyadmin-login" ? (
          <img src={phpmyadmin} width={40} height={40} />
        ) : attack.AttackType === "adminer-login" ? (
          <img src={adminer} width={40} height={40} />
        ) : (
          <img src={filemanager} width={40} height={40} />
        )}
      </>
    );
  };
  return (
    <div
      className={`${
        isMobile ? "p-0 w-100" : "p-3"
      } grap-card-white  h-100 lastattack-container`}
      style={{ overflowX: "hidden" }}
    >
      <div
        className="attack-item"
        style={{ background: "#131521", color: "white" }}
      >
        <div className="row align-items-center px-3">
          <div className=" col-lg-4 d-flex align-items-center justify-content-center">
            <div
              className=" py-3"
              style={{ fontSize: "15px", fontWeight: "600" }}
            >
              <ins style={{ textDecoration: "none",whiteSpace:'nowrap' }}>
                Current Attacks({totalData})
              </ins>
            </div>
          </div>

          <div className="col-lg-8  d-flex align-items-center justify-content-center">
            <div
              className="  d-flex align-items-center justify-content-between w-100 py-2"
              style={{ columnGap: "5px" }}
            >
              <div
                className="px-2 py-1"
                style={{
                  borderRadius: "12px",
                  color: "black",
                  display: "inline",
                  fontSize: "14px",
                  background: "white",
                  cursor: "pointer",
                }}
                onClick={() => setSearch("")}
              >
                CLEAR
              </div>
              <div
                className="px-2 py-1"
                style={{
                  borderRadius: "12px",
                  color: "white",
                  display: "inline",
                  fontSize: "14px",
                  background: "#5599FF",
                  cursor: "pointer",
                }}
                onClick={() => setSearch("INFO")}
              >
                INFO
              </div>
              <div
                className="px-2 py-1"
                style={{
                  borderRadius: "12px",
                  color: "white",
                  display: "inline",
                  fontSize: "14px",
                  background: "#FD7B70",
                  cursor: "pointer",
                }}
                onClick={() => setSearch("WARNING")}
              >
                WARNING
              </div>

              <div
                className="px-2 py-1"
                style={{
                  borderRadius: "12px",
                  color: "white",
                  display: "inline",
                  fontSize: "14px",
                  background: "#B02F34",
                  cursor: "pointer",
                }}
                onClick={() => setSearch("CRITICAL")}
              >
                CRITICAL
              </div>
            </div>
          </div>
        </div>

        {/* <div>
          <div className="d-flex align-items-center">
            <button
              style={{
                fontSize: "32px",
                lineHeight: "2px",
                fontWeight: "bold",
                color: "gray",
                background: "none",
                border: "none",
              }}
              onClick={() => setCount(count - 1 > 0 ? count - 1 : 0)}
            >
              -
            </button>
            <div
              className="py-1 px-2"
              style={{
                boxShadow: "0px 0px 8px 0px #100B2714",
                borderRadius: "8px",
                fontSize: "20px",
              }}
            >
              {count}
            </div>
            <button
              style={{
                fontSize: "32px",
                lineHeight: "2px",
                fontWeight: "bold",
                color: "gray",
                background: "none",
                border: "none",
              }}
              onClick={() => setCount(count + 1)}
            >
              +
            </button>
          </div>
        </div> */}
      </div>

      <div
        style={{
          maxHeight: windowSize?.height * 0.8,
          overflowY: "auto",
          overflowX: "hidden",
        }}
      >
        {data && Array.isArray(data)
          ? filter(data?.slice(0, 10)).map((item) => {
              return (
                <div
                  className="d-flex align-items-center attack-item"
                  style={{ background: "#131521", color: "white" }}
                >
                  <div className="reversecolor">
                    <ImageComp attack={{ AttackType: item?.record?.type }} />
                  </div>
                  <div className="d-flex align-items-center justify-content-between p-1 h-100 w-100">
                    <div className="d-flex p-0 align-items-center ">
                      <div
                        className="px-3 mr-1"
                        style={{
                          borderRadius: "12px",
                          color: "white",
                          display: "inline",
                          fontSize: "14px",
                          background:
                            item?.record?.loglevel === "INFO"
                              ? "#5599FF"
                              : item?.record?.loglevel === "WARNING"
                              ? "#FD7B70"
                              : "#B02F34",
                        }}
                      >
                        {item?.record?.loglevel}
                      </div>
                      <strong style={{ fontSize: "20px", marginLeft: "5px" }}>
                        {item?.record?.type}
                      </strong>
                    </div>
                    <div className="h-100">
                      <div
                        style={{
                          color: "#5599FF",
                          marginTop: "auto",
                          textAlign: "right",
                        }}
                      >
                        <div style={{ color: "white" }}>
                          {item?.attackerLocation?.country} {"->"}{" "}
                          {item?.record?.location}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })
          : null}
      </div>
    </div>
  );
};

export default LastAttacks;
