import React, { useState, useEffect } from 'react';
import { Audio } from 'react-loader-spinner'
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import 'bootstrap/dist/css/bootstrap.min.css'; // Bootstrap CSS
import { Helmet } from "react-helmet";
import { IoPersonCircleSharp } from "react-icons/io5";
import SampleWorld from './SampleWorld';
import { isMobile } from "react-device-detect";
import { Modal } from 'react-bootstrap';
import { FaDownload } from "react-icons/fa6";
import { IoClose } from "react-icons/io5";


const Landing = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [show, setShow] = useState(false);

  const [isNavExpanded, setIsNavExpanded] = useState(false); // Yeni state ekledik
  const toggleNav = () => {
    setIsNavExpanded(!isNavExpanded); // Menünün durumunu güncelle

    if (!isNavExpanded) {
      // Eğer menü şu anda kapalıysa ve açılacaksa, sayfanın en üstüne git
      window.scrollTo({
        top: 0,
        behavior: 'smooth' // Kaydırma işleminin pürüzsüz olmasını sağla
      });
    }
  };
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth' // Pürüzsüz kaydırma işlemi için
    });
  };
  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 500);
    setTimeout(() => {
      setShow(true);
    }, 600);
  }, []);

  const schemaMarkup = {
    "@context": "http://schema.org",
    "@type": "SoftwareApplication",
    "name": "Guardpot",
    "description": "Guardpot offers revolutionary honeypot software designed to attract cyber attackers and provide deep insights into their methods.",
    "applicationCategory": "SecurityApplication",
    "operatingSystem": "Cross-Platform",
    "url": "https://guardpot.com",
    "image": "https://guardpot.com/assets/img/logo512.png",
    "author": {
      "@type": "Organization",
      "name": "Guardpot Inc."
    }
  };

  const [mailState, setMailState] = useState(null);

  const submitMail = async (event) => {
    event.preventDefault()
    const formData = new FormData(event.target)
    try {
      const request = await fetch('https://allarmia.com/api/listeners/6_355932', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          data: {
            notify: `
                          Name:${formData.get('name')}
                          Email:${formData.get('email')}
                          Mesaj:${formData.get('message')}
                          `
          }
        })
      })
      if (request.ok) {
        const response = await request.text();
        if (response === "OK") {
          setMailState('success')
        } else {
          setMailState('failed')
        }
      }
    } catch (error) {
      console.log(error);
      setMailState('failed')
    }
  }

  return (
    <>


      <Helmet>
        <meta charSet="utf-8" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, shrink-to-fit=no"
        />

        <title>Guardpot | Honeypot Cybersecurity Software</title>
        <meta name="description"
          content="Guardpot offers advanced honeypot software to attract cyber attackers and provide insights into their methods. Discover our security solutions." />
        <meta name="keywords"
          content="cyber decoy, honeypot cybersecurity, honeypot software, cyber threat detection, cybersecurity solutions, Guardpot, advanced honeypot, cyber attack analysis, threat intelligence, network security, cybersecurity tools" />
        <link rel="robots" href="https://guardpot.com/robots.txt" />
        <link rel="canonical" href="https://guardpot.com" />
        <meta name="copyright" content="Priente Software" />
        <meta name="author" content="Priente Software Team" />
        <meta property="og:title" content="Guardpot: Advanced Honeypot Cybersecurity Solutions" />
        <meta property="og:description"
          content="Guardpot offers advanced honeypot software to attract cyber attackers and provide insights into their methods. Discover our security solutions." />
        <meta property="og:url" content="https://guardpot.com" />
        <meta property="og:site_name" content="Guardpot" />
        <meta property="og:type" content="website" />
        <meta property="og:image" content="https://guardpot.com/assets/img/logo512.png" />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="@guardpot" />
        <meta name="twitter:title" content="Guardpot: Advanced Honeypot Cybersecurity Solutions" />
        <meta name="twitter:description"
          content="Guardpot offers advanced honeypot software to attract cyber attackers and provide insights into their methods. Discover our security solutions." />
        <meta name="twitter:image" content="https://guardpot.com/assets/img/logo512.png" />

        <script type="application/ld+json">{JSON.stringify(schemaMarkup)}</script>

        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="assets/img/favicon-g.png"
        />
        <link rel="stylesheet" href="assets/bootstrap/css/bootstrap.min.css" />
        <link
          rel="stylesheet"
          href="https://fonts.googleapis.com/css?family=Exo+2&subset=cyrillic,cyrillic-ext&display=swap"
        />
        <link
          rel="stylesheet"
          href="assets/css/Font%20Awesome%205%20Brands.css"
        />
        <link rel="stylesheet" href="assets/css/Font%20Awesome%205%20Free.css" />
        <link
          rel="stylesheet"
          href="https://fonts.googleapis.com/css?family=Open+Sans&display=swap"
        />
        <link
          rel="stylesheet"
          href="https://fonts.googleapis.com/css?family=Varela+Round&display=swap"
        />
        <link rel="stylesheet" href="assets/fonts/fontawesome-all.min.css" />
        <link rel="stylesheet" href="assets/css/animate.min.css" />
        <link rel="stylesheet" href="assets/css/bootstrap.min.css" />
        <link rel="stylesheet" href="assets/css/fontawesome-all.min.css" />
        <link rel="stylesheet" href="assets/css/swiper.css" />
        <link rel="stylesheet" href="assets/css/styles-1.css" />
      </Helmet>


      <div className="App">

        {isLoading ? (
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
            <Audio
              height="80"
              width="80"
              radius="9"
              color="red"
              ariaLabel="loading"
              wrapperStyle
              wrapperClass
            />
          </div>
        ) : (
          <div>
            <>
              {isMobile && (
                <Navbar bg="light" expand="lg" expanded={isNavExpanded}>
                  <Container>
                    <Navbar.Brand href="#header">Guardpot | Honeypot Cybersecurity Software</Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={() => setIsNavExpanded(!isNavExpanded)} />
                    <Navbar.Collapse id="basic-navbar-nav">
                      <Nav className="me-auto">
                        <Nav.Link href="#header" onClick={() => setIsNavExpanded(false)}>Home</Nav.Link>
                        <Nav.Link href="#details" onClick={() => setIsNavExpanded(false)}>Details</Nav.Link>
                        <Nav.Link href="#features" onClick={() => setIsNavExpanded(false)}>Features</Nav.Link>
                        <Nav.Link href="#services" onClick={() => setIsNavExpanded(false)}>Services</Nav.Link>
                        <Nav.Link href="#honeypots" onClick={() => setIsNavExpanded(false)}>Honeypots</Nav.Link>
                        <Nav.Link href="/live" onClick={() => setIsNavExpanded(false)}>Live Map</Nav.Link>
                        <Nav.Link className='tryNowBox' href="/auth/" onClick={() => setIsNavExpanded(false)}>Member Area</Nav.Link>
                      </Nav>
                    </Navbar.Collapse>
                  </Container>
                </Navbar>
              )}

            </>

            <>
              <nav

                className="navbar navbar-light navbar-expand-lg fixed-top"
                id="navbar"
                aria-label="Main navigation"
              >
                <div className="d-flex align-items-center justify-content-between w-100 px-3 " style={{ maxWidth: '1200px', margin: 'auto' }}>
                  <a className="navbar-brand logo-image" href="/">
                    <img
                      src="assets/img/logo.png"
                      alt="guardpot | honeypot cybersecurity software logo"
                      width={124}
                      height={32}
                      style={{ width: 186, height: 48 }}
                    />
                  </a>
                  <div className={`d-flex gap-2 ${isMobile && 'custom-container'}`}>
                    <div className='display-none'>
                      <a href='/auth/'>
                        <IoPersonCircleSharp size={40} color='#B02F34' />

                      </a>
                    </div>
                    <button
                      className="navbar-toggler border-0 p-0"
                      data-bs-toggle="collapse"
                      type="button"
                      id="navbarSideCollapse"
                      aria-label="Toggle navigation"
                      onClick={toggleNav}
                    >
                      <span className="navbar-toggler-icon" />
                    </button>
                  </div>

                  <div
                    className="navbar-collapse offcanvas-collapse"
                    style={{ gap: '10px' }}
                    id="navbarsExampleDefault"
                  >
                    <ul className="navbar-nav navbar-nav-scroll ms-auto">
                      <li className="nav-item">
                        <a
                          className="nav-link active"
                          aria-current="page"
                          href="#header"
                          style={{
                            fontFamily: '"Varela Round", sans-serif',
                            fontWeight: "bold",
                            fontSize: "large",
                            color: "#c41e3a",
                          }}
                          id="home-tab"
                        >
                          Home
                        </a>
                      </li>

                      <li
                        className="nav-item"
                        style={{ fontFamily: '"Varela Round", sans-serif' }}
                      >
                        <a
                          className="nav-link"
                          href="#details"
                          style={{
                            fontFamily: '"Varela Round", sans-serif',
                            fontWeight: "bold",
                            fontSize: "large",
                          }}
                          id="detail-tab"
                        >
                          Details
                        </a>
                      </li>
                      <li
                        className="nav-item"
                        style={{ fontFamily: '"Varela Round", sans-serif' }}
                      >
                        <a
                          className="nav-link"
                          href="#features"
                          style={{
                            fontFamily: '"Varela Round", sans-serif',
                            fontWeight: "bold",
                            fontSize: "large",
                          }}
                          id="detail-tab"
                        >
                          Features
                        </a>
                      </li>

                      <li
                        className="nav-item"
                        style={{ fontFamily: '"Varela Round", sans-serif' }}
                      >
                        <a
                          className="nav-link"
                          href="#services"
                          style={{
                            fontFamily: '"Varela Round", sans-serif',
                            fontWeight: "bold",
                            fontSize: "large",
                          }}
                          id="service-tab"
                        >
                          Services
                        </a>
                      </li>

                      <li
                        className="nav-item"
                        style={{ fontFamily: '"Varela Round", sans-serif' }}
                      >
                        <a
                          className="nav-link"
                          href="#honeypots"
                          style={{
                            fontFamily: '"Varela Round", sans-serif',
                            fontWeight: "bold",
                            fontSize: "large",
                          }}
                          id="service-tab"
                        >
                          Honeypots
                        </a>
                      </li>

                      <li
                        className="nav-item"
                        style={{ fontFamily: '"Varela Round", sans-serif' }}
                      >
                        <a
                          className="nav-link"
                          href="/live"
                          style={{
                            fontFamily: '"Varela Round", sans-serif',
                            fontWeight: "bold",
                            fontSize: "large",
                          }}
                          id="service-tab"
                        >
                          Live Map
                        </a>
                      </li>

                    </ul>
                    <span
                      className="nav-item"
                      style={{ fontFamily: '"Varela Round", sans-serif' }}
                    >
                      <a
                        className="btn-outline-sm"
                        href="/auth/"
                        style={{
                          fontFamily: '"Varela Round", sans-serif',
                          fontWeight: "bold",
                        }}
                        id="signup-btn"
                      >
                        Member Area
                      </a>
                    </span>

                  </div>
                </div>
              </nav>
              <header id="header" className="header">
                <img
                  data-bss-hover-animate="bounce"
                  className="decoration-star"
                  src="assets/img/decoration-star.svg"
                  alt="background decoration star"
                />
                <img
                  data-bss-hover-animate="bounce"
                  className="decoration-star-2"
                  src="assets/img/decoration-star.svg"
                  alt="background decoration star"
                />

                <div className="container">
                  <div className="row">
                    <div className="col-12 col-xl-5">
                      <div className="text-container">
                        <h1
                          className="h1-large"
                          style={{
                            color: "#3c3c3c",
                            fontFamily: '"Varela Round", sans-serif',
                            fontSize: 55,
                          }}
                          id="header-h1"
                        >
                          Powerful Honeypots in Safety
                        </h1>
                        <p
                          className="p-large"
                          style={{
                            fontFamily: '"Varela Round", sans-serif',
                            color: "#3c3c3c",
                          }}
                          id="header-p1"
                        >
                          Guardpot discreetly fortifies networks, detecting threats
                          covertly for enhanced cybersecurity measures.
                        </p>
                        <a
                          className="btn-solid-lg"
                          href="#details"
                          style={{ fontFamily: '"Varela Round", sans-serif' }}
                          id="header-a1"
                        >
                          More Details
                        </a>
                        <a
                          className="btn-outline-lg"
                          href="#contact"
                          style={{ fontFamily: '"Varela Round", sans-serif' }}
                          id="header-a2"
                        >
                          Request a Demo!
                        </a>
                      </div>
                    </div>
                    <div className="col-12 col-xl-7">
                      <SampleWorld />

                    </div>
                  </div>
                </div>
              </header>
              <div
                className="d-flex justify-content-center align-items-center basic-1 bg-gray"
                id="details"

                style={{ background: "#c41e3a" }}
              >
                <div className="container d-flex justify-content-center align-items-center">
                  <div className="row">
                    <div className="col-xl-9 text-start">
                      <h2
                        style={{
                          color: "var(--bs-gray-100)",
                          paddingTop: 32,
                          fontFamily: '"Varela Round", sans-serif',
                        }}
                      >
                        Guardpot:
                        <span style={{ fontWeight: "normal !important" }} id="intro-s1">
                          Revolutionary Honeypot Solution for Advanced Security and
                          Threat Detection
                        </span>
                      </h2>
                      <p
                        style={{
                          color: "var(--bs-body-bg)",
                          fontFamily: '"Varela Round", sans-serif',
                        }}
                        id="intro-p1"
                      >
                        Guardpot is a honeypot software equipped with advanced agents
                        that detect and prevent covert network attacks. It identifies
                        password leaks in platforms like MS Active Directory, OpenLDAP,
                        taking automated actions, and ensures network security by
                        testing authorized users with fake notifications. With
                        customizable sensors and continuous monitoring, it fortifies
                        networks while its adaptable singularity sets the foundation for
                        future security solutions.
                      </p>
                    </div>
                    <div className="col d-flex justify-content-center align-items-center">
                      <img
                        width={100}
                        height={80}
                        style={{ width: 200, height: 200 }}
                        src="assets/img/info-gif.gif"
                        alt="background gif"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="d-flex justify-content-center align-items-center basic-2"
                id="features"
              >
                <img
                  data-bss-hover-animate="bounce"
                  className="decoration-star"
                  src="assets/img/decoration-star.svg"
                  alt="background decoration star"
                />
                <div className="container">
                  <div className="row">
                    <div className="col-lg-6 col-xl-5 d-flex justify-content-center align-items-center">
                      <div className="image-container">
                        <img
                          className="img-fluid"
                          src="assets/img/admin.webp"
                          alt="human tech interaction"
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 col-xl-7 d-flex justify-content-center align-items-center">
                      <div className="text-container">
                        <h2
                          style={{
                            fontFamily: '"Varela Round", sans-serif',
                            color: "#3c3c3c",
                          }}
                        >
                          <strong id="detail-h1">
                            Redefining Network Security with Four Standout Features
                          </strong>
                        </h2>
                        <ul className="list-unstyled li-space-lg">
                          <li className="d-flex">
                            <i className="fas fa-square" />
                            <div
                              className="flex-grow-1"
                              style={{ fontFamily: "Varela Round, sans-serif" }}
                              id="detail-desc1"
                            >
                              <strong>Stealthy Threat Detection:</strong>
                              Guardpot discreetly identifies password leaks in platforms
                              like MS Active Directory and OpenLDAP, triggering
                              automated responses for immediate action.
                            </div>
                          </li>
                          <li className="d-flex">
                            <i className="fas fa-square" />
                            <div
                              className="flex-grow-1"
                              style={{ fontFamily: "Varela Round, sans-serif" }}
                              id="detail-desc2"
                            >
                              <strong>Customizable Fake Alerts:</strong> It sends
                              simulated notifications to authorized users on set dates,
                              reporting back to administrators upon login, ensuring
                              proactive security measures.
                            </div>
                          </li>
                          <li className="d-flex">
                            <i className="fas fa-square" />
                            <div
                              className="flex-grow-1"
                              style={{ fontFamily: "Varela Round, sans-serif" }}
                              id="detail-desc3"
                            >
                              <strong>Live Security Monitoring:</strong> It attracts attackers by imitating products such as Fortigate, PaloAlto, MS Exchange. It categorizes attackers by monitoring their movements and collects these lists in a common pool in accordance with your permissions.
                              Guardpot cloud also makes available to you data from sensors positioned in more than one country.
                            </div>
                          </li>
                          <li className="d-flex">
                            <i className="fas fa-square" />
                            <div
                              className="flex-grow-1"
                              style={{ fontFamily: "Varela Round, sans-serif" }}
                              id="detail-desc4"
                            >
                              <strong>Versatile Agent Integration: </strong>Guardpot's
                              singular agents are adaptable, enabling seamless
                              utilization in various applications, supporting log
                              reporters, analytics creators, and selectively enabled
                              roles.
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                id="services"
                className="cards-1 bg-gray"
                style={{
                  background: 'url("assets/img/background-gif.png")',
                  backgroundSize: "cover",
                }}
              >
                <div className="container">
                  <div className="row">
                    <div className="col-lg-5">
                      <div className="text-container">
                        <h2
                          style={{
                            fontFamily: '"Varela Round", sans-serif',
                            color: "#3c3c3c",
                          }}
                          id="service-h1"
                        >
                          Services that we offer
                        </h2>
                        <p
                          style={{
                            fontFamily: '"Varela Round", sans-serif',
                            color: "#3c3c3c",
                          }}
                          id="service-p1"
                        >
                          Guardpot, cutting-edge security innovation, offers seamless
                          threat detection like never before. Its customizable alerts
                          and live monitoring elevate proactive network protection.
                        </p>
                        <p
                          style={{
                            fontFamily: '"Varela Round", sans-serif',
                            color: "#3c3c3c",
                          }}
                          id="service-p2"
                        >
                          With flexible agent integration and centralized log systems,
                          it ensures holistic security. Unique sensor pairing capability
                          ensures uninterrupted surveillance, even amidst central access
                          disruptions.
                        </p>
                      </div>
                    </div>
                    <div className="col-lg-7">
                      <div className="card-container">
                        <div className="card">
                          <div
                            className="card-icon d-flex align-items-center justify-content-center"
                            style={{ background: "var(--bs-gray-400)" }}
                          >
                            <span
                              className="fas m-auto fa-rocket"
                              style={{ color: "#c41e3a" }}
                            />
                          </div>
                          <div className="card-body">
                            <h5
                              className="card-title"
                              style={{
                                fontFamily: '"Varela Round", sans-serif',
                                textAlign: "center",
                                color: "#c41e3a",
                              }}
                            >
                              <strong id="service-feat1">
                                Covert Threat Identification
                              </strong>
                            </h5>
                          </div>
                        </div>
                        <div className="card">
                          <div
                            className="card-icon d-flex align-items-center justify-content-center"
                            style={{ background: "var(--bs-gray-400)" }}
                          >
                            <span
                              className="far m-auto fa-clock"
                              style={{ color: "#c41e3a" }}
                            />
                          </div>
                          <div className="card-body">
                            <h5
                              className="card-title"
                              style={{
                                fontFamily: '"Varela Round", sans-serif',
                                color: "#c41e3a",
                              }}
                            >
                              <strong id="service-feat2">
                                Tailored False Alarm System
                              </strong>
                            </h5>
                          </div>
                        </div>
                        <div className="card">
                          <div
                            className="card-icon d-flex align-items-center justify-content-center"
                            style={{ background: "var(--bs-gray-400)" }}
                          >
                            <span
                              className="far m-auto fa-comments"
                              style={{ color: "#c41e3a" }}
                            />
                          </div>
                          <div className="card-body">
                            <h5
                              className="card-title"
                              style={{
                                fontFamily: '"Varela Round", sans-serif',
                                color: "#c41e3a",
                              }}
                            >
                              <strong id="service-feat3">
                                Live Security Monitoring
                              </strong>
                            </h5>
                          </div>
                        </div>
                        <div className="card">
                          <div
                            className="card-icon d-flex align-items-center justify-content-center"
                            style={{ background: "var(--bs-gray-400)" }}
                          >
                            <span
                              className="fas m-auto fa-tools"
                              style={{ color: "#c41e3a" }}
                            />
                          </div>
                          <div className="card-body">
                            <h5
                              className="card-title"
                              style={{
                                fontFamily: '"Varela Round", sans-serif',
                                color: "#c41e3a",
                              }}
                            >
                              <strong id="service-feat4">Sensor Network</strong>
                            </h5>
                          </div>
                        </div>
                        <div className="card">
                          <div
                            className="card-icon d-flex align-items-center justify-content-center"
                            style={{ background: "var(--bs-gray-400)" }}
                          >
                            <span
                              className="fas m-auto fa-chart-pie"
                              style={{ color: "#c41e3a" }}
                            />
                          </div>
                          <div className="card-body">
                            <h5
                              className="card-title"
                              style={{
                                fontFamily: '"Varela Round", sans-serif',
                                color: "#c41e3a",
                              }}
                            >
                              <strong id="service-feat5">Centralized Log System</strong>
                            </h5>
                          </div>
                        </div>
                        <div className="card">
                          <div
                            className="card-icon d-flex align-items-center justify-content-center"
                            style={{ background: "var(--bs-gray-400)" }}
                          >
                            <span
                              className="far m-auto fa-chart-bar"
                              style={{ color: "#c41e3a" }}
                            />
                          </div>
                          <div className="card-body">
                            <h5
                              className="card-title"
                              style={{
                                fontFamily: '"Varela Round", sans-serif',
                                color: "#c41e3a",
                              }}
                            >
                              <strong id="service-feat6">
                                Sensor Pairing Capability
                              </strong>
                            </h5>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="basic-3"
                id='honeypots'
              >
                <img
                  data-bss-hover-animate="bounce"
                  className="decoration-star"
                  src="assets/img/decoration-star.svg"
                  alt="background decoration star"
                />
                <div className="container">
                  <div className="row">
                    <div className="col-lg-6 col-xl-7">
                      <div className="text-container">
                        <h2
                          style={{ fontFamily: '"Varela Round", sans-serif' }}
                          id="basic-h1"
                        >
                          Honeypots in Cybersecurity
                        </h2>
                        <p
                          style={{ fontFamily: '"Varela Round", sans-serif' }}
                          id="basic-p1"
                        >
                          Honeypots excel in revealing system vulnerabilities,
                          especially detecting threats like attacks on IoT devices.
                          Their key advantages include precise intrusion detection due
                          to their nature of attracting only suspicious activities. This
                          focused approach aids in identifying patterns and potential
                          attacks, offering insights often missed amidst high legitimate
                          traffic. Honeypots are resource-light, adaptable to old
                          hardware, and provide low false positives, contrasting
                          traditional Intrusion Detection Systems. Additionally, they
                          can enhance overall cybersecurity by complementing and
                          refining other security systems.
                        </p>
                      </div>
                    </div>
                    <div className="col-lg-6 col-xl-5">
                      <div className="image-container">
                        <img
                          className="img-fluid"
                          src="assets/img/info-gif-4.png"
                          alt="background decoration"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="basic-4 bg-gray"
                style={{
                  background: 'url("assets/img/coming-soon-gif.png")',
                  backgroundSize: "cover",
                }}
              >
                <div className="container">
                  <div className="row">
                    <div className="col-lg-12">
                      <h4
                        style={{
                          fontFamily: '"Varela Round", sans-serif',
                          color: "var(--bs-light)",
                        }}
                      >
                        <div id="relase-p1">The wait is over!</div>
                        <br />
                        <br />
                        <span
                          id="relase-p2"
                          style={{ fontWeight: "normal !important" }}
                        >
                          Guardpot is now available! Discover a fast and innovative way to secure your networks. Strengthen your defenses with Guardpot and keep your systems safe.

                        </span>
                      </h4>
                      <a
                        className="btn-solid-lg"
                        href="#contact"
                        style={{ fontFamily: '"Varela Round", sans-serif' }}
                        id="relase-a1"
                      >
                        Request a Demo
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div id="contact" className="form-1">
                <img
                  data-bss-hover-animate="bounce"
                  className="decoration-star"
                  src="assets/img/decoration-star.svg"
                  alt="background decoration star"
                />
                <img
                  data-bss-hover-animate="bounce"
                  className="decoration-star-2"
                  src="assets/img/decoration-star.svg"
                  alt="background decoration star"
                />
                <div className="container">
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="image-container">
                        <img
                          className="img-fluid"
                          src="assets/img/request.webp"
                          alt="human contact card"
                        />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="text-container">
                        <h2
                          style={{
                            fontFamily: '"Varela Round", sans-serif',
                            color: "#3c3c3c",
                          }}
                          id="contact-h1"
                        >
                          Request a Demo
                        </h2>
                        {mailState === null ?
                          <>
                            <form onSubmit={submitMail}>
                              <div className="form-group">
                                <input
                                  type="text"
                                  className="form-control-input"
                                  placeholder="Name"
                                  name='name'
                                  required=""
                                  style={{ fontFamily: '"Varela Round", sans-serif' }}
                                  id="input1"
                                />
                              </div>
                              <div className="form-group">
                                <input
                                  type="email"
                                  className="form-control-input"
                                  placeholder="E-Mail"
                                  name='email'
                                  required=""
                                  style={{ fontFamily: '"Varela Round", sans-serif' }}
                                  id="input2"
                                />
                              </div>
                              <div className="form-group">
                                <textarea
                                  className="form-control-textarea"
                                  placeholder="Message"
                                  required=""
                                  name='message'
                                  style={{ fontFamily: '"Varela Round", sans-serif' }}
                                  id="input3"
                                  defaultValue={""}
                                />
                              </div>
                              <div className="form-group">
                                <button
                                  type="submit"
                                  className="form-control-submit-button"
                                  style={{ fontFamily: '"Varela Round", sans-serif' }}
                                  id="send-btn"
                                >
                                  Submit
                                </button>
                              </div>
                            </form>
                          </> : mailState === 'success' ?
                            <>
                              <div className='successText'>
                                <h4 className=''>Thanks for your interest!</h4>
                                <h6 className=''>We will get back to you soon.</h6>

                              </div>
                            </>
                            :
                            <>
                              <div className='successText'>
                                <h4 className=''>A problem occured!</h4>
                                <h6 className=''>Please try again later.</h6>

                              </div>
                            </>}

                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="footer bg-gray" style={{ background: "#c41e3a" }}>
                <div className="container">
                  <div className="row">
                    <div className="col-lg-12 text-center d-xl-flex justify-content-xl-end">
                      <div className="footer-col first">
                        <h6
                          style={{
                            color: "var(--bs-light)",
                            fontFamily: '"Varela Round", sans-serif',
                          }}
                          id="about-h1"
                        >
                          About Guardpot
                        </h6>
                        <p
                          className="p-small"
                          style={{
                            color: "var(--bs-light)",
                            fontFamily: '"Varela Round", sans-serif',
                          }}
                          id="about-p1"
                        >
                          Innovating security, threat detection, and network protection
                          solution with innovation.
                        </p>
                      </div>
                      <div className="text-center footer-col third">
                        <a href="tel:+903124000061">
                          <span className="fa-stack">
                            <i className="fas fa-circle fa-stack-2x" />
                            <i className="fa fa-phone fa-stack-1x" />

                          </span>
                        </a>
                        <a href="https://prientegroup.com">
                          <span className="fa-stack">
                            <i className="fas fa-circle fa-stack-2x" />
                            <i className="fa fa-globe fa-stack-1x" />
                          </span>
                        </a>
                        <p
                          className="text-center p-small"
                          style={{
                            fontFamily: '"Varela Round", sans-serif',
                            color: "var(--bs-light)",
                          }}
                          id="about-p2"
                        >
                          We would love to hear from you!
                        </p>
                      </div>
                      <div className='d-flex flex-column justify-items-center align-items-center px-5'>
                        <img
                          className=" text-white mb-3"
                          src={require('./assets/siber.png')}
                          alt="siber kümelenme"
                          style={{
                            width: '200px'
                          }}
                        />
                        <img
                          className=" text-white"
                          src={require('./assets/yerli.png')}
                          alt="yerli üretim"
                          style={{
                            width: '100px',
                            filter:'brightness(0) invert(1)'
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="copyright bg-gray" style={{ height: 60 }}>
                <div className="container">
                  <div className="row">
                    <div className="col-lg-12">
                      <p
                        className="p-small"
                        style={{
                          fontFamily: '"Varela Round", sans-serif',
                          fontWeight: "bold",
                          paddingTop: 8,
                        }}
                      >
                        Copyright © Priente Software
                      </p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-12">
                      <p
                        className="p-small"
                        style={{
                          fontFamily: '"Varela Round", sans-serif',
                          fontWeight: "bold",
                        }}
                      >
                        Designed By <a href="https://prientesoftware.com">Priente</a>
                        Software Team
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <button id="myBtn" onClick={scrollToTop}>
                <img src="assets/img/up-arrow.png" alt="alternative" />
              </button>
            </>
          </div>
        )}
      </div>

      <div className={`custompopup ${!show && 'custompopupclosed'} `}>
        <button
          className='closeButtonPopUp'
          onClick={() => setShow(false)}
        >
          <IoClose color='white' size={24} />
        </button>
        <div className='text-white' >Monthly Threat Report</div>
        <a href={require("./assets/GuardpotMonthlyReport.pdf")} download="GuardpotMonthlyReport.pdf" className='buttondownload'>DOWNLOAD
          <FaDownload />
        </a>

      </div>

    </>
  );
}

export default Landing;






